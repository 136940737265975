@import 'styles/utilities.scss';
.isPortrait {
  width: 80%;

  @include tablet {
    width: 50%;
  }

  // img {
  //   width: auto !important;
  //   height: 65vh !important;
  //   margin: 0 auto !important;
  // }

  &.inviteCard {
    display: flex;
    justify-content: center;
  }

  .inviteCardInner {
    // width: 100%;
    // @include tablet {
    //   width: 50%;
    // }
  }
}

.isNotPortrait {
  width: 100%;
}
.inviteCard {
  // height: 50vh;
  // @include tablet {
  //   height: 85vh;
  // }
  &:first-of-type {
    // height: 85vh;
  }
  // margin-bottom: calc(10 * var(--page-padding-x));
  iframe {
    // height: 85vh !important;

    body {
      background: rgba(255, 255, 255, 0) !important;
    }
  }
  // margin-bottom: var(--spacing-x-large);

  a {
    text-decoration: none;

    h2:hover,
    h2:focus {
      text-decoration: underline;
    }
  }
}

.cardWrapper {
  position: relative;
  width: 100%;
  margin-bottom: var(--spacing-large);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include tablet {
    margin-bottom: var(--spacing-x-large);
  }
}

.inviteCardInner {
  position: relative;
  width: 100%;
}

.inviteInfo {
  // margin-bottom: var(--spacing-large);
  position: relative;
  max-width: 80%;

  @include tablet {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 300px;
    max-width: 60%;
  }

  @include desktop {
    // max-width: 50%;
  }

  // transition: left 0.2s ease-in-out, top 0.2s ease-in-out;

  // Reduce size for Elizabeth Molin
  padding: var(--spacing-small);

  &-label {
    display: none;
    font-size: var(--font-size-normal);
  }

  h1 {
    font-size: var(--font-size-normal);
    margin-bottom: var(--spacing-x-small);
  }

  h3 {
    margin-top: var(--spacing-x-small);
    font-size: var(--font-size-medium);
  }

  h3:nth-of-type(2n) {
    margin-bottom: var(--spacing-x-small);
  }

  h4 {
    margin-top: var(--spacing-x-small);
    margin-bottom: var(--spacing-x-small);
    font-size: var(--font-size-small);
  }
}

.animateInfo {
  transition:
    left 0.2s ease-in-out,
    top 0.2s ease-in-out;
}

.cardImage {
  position: relative;

  > a {
    padding: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
  }
  // display: inline-block;
  > div {
    width: 100%;
    position: unset !important;
  }
}

.cardEmbed {
  height: 100%;
  background: rgb(248, 248, 248);
  // height: 80vh;
  > div {
    height: 100%;
  }
}

.cardInfo {
  padding: var(--spacing-normal) var(--spacing-x-normal) var(--spacing-x-normal)
    var(--spacing-x-normal);
  @include desktop {
    padding: var(--spacing-x-normal) var(--spacing-large)
      var(--spacing-xx-normal) var(--spacing-x-normal);
  }
  background-color: var(--color-card-bg);
  h2 {
    margin: var(--spacing-small) 0;
  }
  p {
    margin: var(--spacing-x-small) 0;
  }
}
