@import 'styles/utilities.scss';
.modelviewer {
  model-viewer {
    width: 100%;
    height: initial;
    // min-height: 20vh;
    max-height: 80vh;
    aspect-ratio: 16 / 9;
  }
}

.modelviewerWrapper {
  width: 100%;
  height: initial;
  max-height: 80vh;
  aspect-ratio: 16 / 9;
}
