@import 'styles/utilities.scss';
.gifWrapper {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.gif-hidden {
  width: 100%;
  padding-bottom: 66.6667%;
}

.imageWrapper {
  line-height: 0;
  img {
    width: 100%;
    height: auto;
  }
}
