@import 'styles/utilities.scss';
.media {
  padding-top: var(--embed-aspect, 56.25%);

  > * {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border: 0;
  }
}

.vimeo {
  > span {
    pointer-events: none;
  }
}
